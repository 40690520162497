export default {
  steps: state => state.steps,
  currentStepId: state => state.currentStepId,
  currentSubstepId: state => state.currentSubstepId,
  stepsData: state => state.stepsData,

  applicantId: state => state.applicantId,
  checkId: state => state.checkId,
  checkStatus: state => state.checkStatus,
  sdkToken: state => state.sdkToken,
  isOnfidoCheckInProgress: state => state.checkStatus === 'CHECK_CREATED',
  isOnfidoCheckFailed: state => state.checkStatus === 'CHECK_FAILED',
  isOnfidoCheckCompleted: state => state.checkStatus === 'CHECK_COMPLETED',
  hasOnfidoCheckId: state => state.checkId !== null
};
