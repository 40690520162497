<template>
  <div class="container" v-if="isMobileWorkerRegisterFlow">

    <WorkerRegistrationStepLayout no-cta>
      <template v-slot:after-header>

        <div class="text-center">
          <h1>Aw, Snap!</h1>
          <h2>Something went terribly wrong, and our team has been notified about this incident.<br/><br/>Try again later, or contact our customer service</h2>
          <a href="/worker/register">Home page</a>
          {{ error }}
        </div>
      </template>
      <template v-slot:after-cta>
        <HelpMessageBox title="Problem is still there?"/>
      </template>
    </WorkerRegistrationStepLayout>
  </div>
  <div class="container" v-else>
    <h1 v-if="error.statusCode === 404">404 Page not found</h1>
    <h1 v-else>An error occurred</h1>
    <p>{{ error.statusCode }} {{ error.message }}</p>
    <a href="/">Home page</a>
  </div>
</template>

<script>
import errorLayout                  from "@/middleware/errorLayout";
import WorkerRegistrationTopNav     from "@/components/WorkerRegistration/WorkerRegistrationTopNav";
import WorkerRegistrationStepLayout from "@/components/WorkerRegistration/WorkerRegistrationStepLayout";
import MessageBox                   from "@/components/WorkerRegistration/MessageBox";
import HelpMessageBox               from "@/components/WorkerRegistration/HelpMessageBox";
import {isMobileWorkerRegisterFlow} from "@/helpers/environment";

const getLayoutForErrorPage = () => {
  // if(i)
  return undefined;
}
export default {
  props: ['error'],
  layout: errorLayout,
  components: {HelpMessageBox, WorkerRegistrationStepLayout, WorkerRegistrationTopNav},
  computed: {
    isMobileWorkerRegisterFlow() {
      return isMobileWorkerRegisterFlow(this.$route)
    }
  }
};
</script>
