<template>
  <div class="registration-wizard-wrap">
    <WorkerRegistrationTopNav
        sticky v-if="topNav">
      <template v-slot:left_slot v-if="withGoBack">
        <div @click="$emit('goBack')" class="cursor-pointer go-back">
          <img
              src="~/assets/images/chevron.svg"
              class="chevron left"
          />
        </div>
      </template>
    </WorkerRegistrationTopNav>

    <VerificationProgressHeader v-if="progress"/>

    <form @submit.prevent="handleEnterKeyPress" class="step-content">
      <div class="container header">
        <slot name="header">
          <h1 v-if="title">{{ title }}</h1>
        </slot>
        <slot name="after-header"></slot>
      </div>

      <div class="filler"></div>

      <div class="footer">
        <slot name="footer"></slot>
      </div>

      <div class="cta-wrap">
        <!-- hidden <input type=submit> to handle enter keypress, while keeping HTML5 simple inputs validation -->
        <input type="submit" ref="submitBtn" value="Submit"
               style="opacity: 0;left:-9999px;position: absolute;tab-index: -1;visibility: hidden"/>
        <UiButton @click.prevent="$refs.submitBtn.click()" :disabled="disabled" block v-if="!noCta">{{ ctaTitle }}</UiButton>
      </div>

      <div class="after-cta">
        <div class="text-center my-4">
          <slot name="after-cta"></slot>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import "~/assets/scss/worker-registration.scss";
import WorkerRegistrationTopNav   from "@/components/WorkerRegistration/WorkerRegistrationTopNav";
import UiButton                   from "@/components/ui/UiButton";
import debuglog                   from "@/helpers/debuglog";
import {isDebug, isInWebView}     from "@/helpers/environment";
import VerificationProgressHeader from "@/components/WorkerRegistration/VerificationProgressHeader";

export default {
  name: "WorkerRegistrationStepLayout",
  components: {VerificationProgressHeader, UiButton, WorkerRegistrationTopNav},
  props: {
    ctaTitle: {
      type: String,
      default: "Continue"
    },
    noCta: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    topNav: {
      type: Boolean,
      default: true
    },
    progress: {
      type: Boolean,
      default: false
    },
    withGoBack: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    window.addEventListener('keydown', this.handleGlobalKeyPress)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.handleGlobalKeyPress)
  },
  methods: {
    handleGlobalKeyPress(event) {
      debuglog(event);
      if (isDebug() && !isInWebView() && event.code === "Enter") {
        this.$refs.submitBtn.click();
      }
    },
    handleClick() {
      if (window) window.scrollTo(0,0)

      if (this.noCta) {
        return;
      }
      this.$emit('ctaClick');
    },
    // StepLayout is wrapped with dummy <form> to detect 'enter' keypresses on any of the inputs inside. on 'enter' it simulates click of CTA button
    handleEnterKeyPress(event) {
      this.handleClick();
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_worker_registration_vars.scss";

.registration-wizard-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //padding-bottom: $step-padding-y;
  position: relative;
  background-image: url(~@/assets/images/worker-registration/registration-wizard-bg.png);
  background-size: 130% auto;
  background-position: 30% 110%;
  background-repeat: no-repeat;
  margin-bottom: 40vh;

  .go-back{
    padding: 10px $container-padding;
  }
  .cta-wrap {
    padding: 0 $cta-padding-x;
  }

  .filler {
    flex-grow: 1;
  }

  .footer {
    padding-bottom: $step-padding-y;
  }

  .step-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: $step-padding-y 0;
  }

  .header {
    h1{
      margin-bottom: 16px;
    }
    &{
      color: #303030;

    }
  }

  .registration-wizard__super-title {
    font-size: 45px;
  }
}
</style>
