import {EventBus}                                     from "@/plugins/event-bus";
import ReactNativeChannelMixin, {ReactNativeMessages} from "@/mixins/reactNativeChannel";
import LocalStorage                                   from "@/helpers/local-storage";
import {getWebViewInjectedConfig, isInWebView}        from "@/helpers/environment";

const overwriteConsole = () => {
  if (console._originalLog) {
    return;
  }
  const original = {
    log: console.log.bind(console),
    debug: console.debug.bind(console),
    error: console.error.bind(console),
    warn: console.warn.bind(console),
  }
  const postMessage = (args, level) => {
    args = [].slice.call(args);
    const lastArg = args[args.length - 1];
    setTimeout(() => {
      if (typeof lastArg === "string" && lastArg.indexOf && lastArg.indexOf('font-weight:') !== -1) {
        args.pop();
      }
      ReactNativeChannelMixin.methods.webViewPostMessage(
        ReactNativeMessages.CONSOLE_LOG,
        {applyArguments: args, level}
      )
    }, 0);
  }
  //TODO: communicate to parent
  console.log = function () {
    original.log.apply(console, arguments);
    postMessage(arguments, 'log');
  }
  console.error = function () {
    original.error.apply(console, arguments);
    postMessage(arguments, 'error');
  }

  console.debug = function () {
    original.debug.apply(console, arguments);
    postMessage(arguments, 'debug');
  }

  console.warn = function () {
    original.warn.apply(console, arguments);
    postMessage(arguments, 'warn');
  }
  console._originalLog = original.log;
  console._originalDebug = original.debug;
  console._originalError = original.error;
  console._originalWarn = original.warn;

}
export default ({context, $axios, route}) => {
 
  /** dont initialize in browsers **/
  if (!isInWebView()) {
    console.debug("[WEBVIEW] Running mode: browser");
    return;
  }
  overwriteConsole();
  console.debug("[WEBVIEW] Running mode: webview")
  const injectedConfig = getWebViewInjectedConfig();
  console.debug("injected config", injectedConfig);
  if (injectedConfig.nativeConfig && injectedConfig.nativeConfig.apiUrl) {
    // TODO: assert we are using the same api
  }
  /**
   * Propagate auth token to ReactNative whether we get new token, so parent app is always up to date
   */
  LocalStorage.listen('new-token', ({token}) => {
    ReactNativeChannelMixin.methods.webViewPostMessage(ReactNativeMessages.NEW_AUTH_TOKEN_RECEIVED, {token});
    /// here is possible solution to not synchronised login state between web and webview
    //RS Dec 2024 this.$store.dispatch('worker-registration/allDone');
  });
  /**
   * Listen for new token from parent. Process it, but dont notify parent back
   */
  ReactNativeChannelMixin.methods.webViewListen(ReactNativeMessages.NEW_AUTH_TOKEN_RECEIVED, ({token}) => {
    LocalStorage.setToken(token, true);
  });
  // ReactNativeChannelMixin.methods.webViewListenGuardToken(ReactNativeMessages.GUARD_TOKEN, ({guardToken}) => {
  //   localStorage.setItem('guard-token', guardToken);
  // });

}
