export default {
  hasData: state => {
    return state.hasData
  },
  isInitialised: state => {
    return state.isInitialised
  },
  getDict: state => {
    return state.data
  },

  getContractStatus: state => {
    return state.static['CONTRACT_STATUS']
  },

  getWorkerContractStatus: state => {
    return state.static['WORKER_CONTRACT_STATUS']
  },

  getWorkerStatus: state => {
    return state.static['WORKER_STATUS']
  },

  getJobStatus: state => {
    return state.static['JOB_STATUS']
  },

  getExperiences: state => {
    return state.data['EXPERIENCE_TYPE']
  },

  getLanguages: state => {
    return state.data['LANGUAGE']
  },

  getQualifications: state => {
    return state.data['QUALIFICATION']
  },

  getRoles: state => {
    return state.data['ROLE']
  },

  getSkills: state => {
    return state.data['SKILL']
  },

  getEmploymentTypes: state => {
    return state.data['EMPLOYMENT_TYPE']
  },

  getEnglishLevel: state => {
    return state.data['ENGLISH_LEVEL']
  },

  getSalaryPeriod: state => {
    return state.data['SALARY_PERIOD']
  },

  getAvailibility: state => {
    return state.data['AVAILABILITY']
  },

  getTravelTime: state => {
    return state.data['TRAVEL_TIME']
  },

  getExperienceYears: state => {
    return state.data['EXPERIENCE_YEARS']
  },

  getLocations: state => {
    return state.locations
  },

  getPaymentCycle: state => {
    return state.static['PAYMENT_CYCLE']
  },

  getPaymentTypes: state => {
    return state.data['PAYMENT_TYPES']
  },

  getWorkerType: state => {
    return state.data['CONTRACT_TYPES']
  },

  getCertificateType: state => {
    return state.static['CERTIFICATE_TYPE']
  },

  getCSCSSchemes: state => {
    return state.static['CSCS_SCHEMES']
  },

  getCSCSSchemes2: state => {
    return state.static['CSCS_SCHEMES2']
  },

  matchRole: state => {
    if (state.data['ROLE']) {
      return id =>
        state.data['ROLE'].filter(role => {
          return role.id === id
        })[0] || 'role not found'
    }
  },

  getCountries: state => {
    return state.static['COUNTRIES']
  },

  getNationalites: state => {
    return state.static['NATIONALITIES']
  },

  getCompanySize: state => {
    return state.static['COMPANY_SIZE']
  },

  getCompanyTurnover: state => {
    return state.static['COMPANY_TURNOVER']
  },
};
