export default {
  async init({ dispatch, state }) {
    if (!state.isInitialised) {
      dispatch('fetch')
    }
  },

  async fetch({ commit }) {
    try {
      commit('toggleInitialised', true)
      const getDict = this.$axios.get('/api/dict')
      //const getLoc = this.$axios.get('/api/locations')
      const dict = await getDict
  
      commit('setDict', dict.data.data)
      //const locs = await getLoc
      commit('setLocations', {
        'LOCATION': [],
        'REGION': []
      })
      commit('setHasData')
    } catch (err) {
      commit('toggleInitialised', false)
    }
  },
};
