export default () => ({
  currentStepId: null,
  currentSubstepId: null,
  phoneNumber: null,
  // is it first time code or request for resend
  hasResentCode: false,
  verificationCode: null,

  //onfido
  applicantId: null,
  checkId: null,
  checkStatus: null,
  sdkToken: null,

  stepsData: null
});
