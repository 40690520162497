export default {
  setDict: (state, data) => {
    const otherPT = data['PAYMENT_TYPES'].find( pt => pt.id === 'Other')
    if (otherPT) {
      otherPT.value = 'Other/Not Used'
    }

    state.data = data
  },
  setLocations: (state, data) => {
    state.locations = data
  },
  setHasData: state => {
    state.hasData = true
  },
  toggleInitialised: (state, flag) => {
    state.isInitialised = flag
  },
};
